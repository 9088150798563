import React, { useState, useEffect, useCallback, useRef } from 'react';
// import ReactGA from 'react-ga';
import clsx from 'clsx'
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../../../../Components/Helpers/Loading';
// import ReloadIcon from '@rsuite/icons/Reload';
import styles from './styles.module.css';
import HeaderModule from '../../../../../../Components/HeaderModule/HeaderModule';
import apiConnectSourcing from '../../../../../../services/apiConnectSourcing';
import ModalProspectionTask from './Components/ModalProspectionTask';
import ModalProspectionResult from './Components/ModalProspectionResult';
import ModalInsertSupplier from './Components/ModalInsertSupplier';
import ModalProspectionSimulator from './Components/ModalProspectionSimulator';
import ModalProspectionSupplierRestart from './Components/ModalProspectionSupplierRestart';
import ModalChangeStatus from './Components/ModalChangeStatus';
import ModalExportMassive from './Components/ModalExportMassive';
import apiConnect from '../../../../../../services/apiConnect';
import Tabs from '../../../../../../Components/Tabs';
import SupplierContactCollapse from './Components/SupplierContactCollapse';
import { UncontrolledCollapse, UncontrolledTooltip, Tooltip } from 'reactstrap';
import {
  Alert,
  SelectPicker,
  Col,
  Icon,
  Row,
  Button,
  Loader,
  Container,
} from 'rsuite';
import { classNames } from '../../../../../../utils/utils';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { RiFolderDownloadLine, RiFolderUploadLine } from 'react-icons/ri';
import { useCognitoAuth } from '../../../../../../Contexts/AuthCognitoContext';
import { PROJECT_TYPE } from '../../../../Management/constants/constants';
import { ReactComponent as LinkIcon } from '../../../../../../Assets/link-ico.svg';
import { ReactComponent as ReloadCompIcon } from '../../../../../../Assets/retry-comp-icon.svg';
import ModalCostSheetHelp from './Components/ModalCostSheetHelp/ModalCostSheetHelp';
import ModalRemoveSupplier from './Components/ModalRemoveSupplier/ModalRemoveSupplier';
import ModalUploadCostSheet from './Components/ModalUploadCostSheet/ModalUploadCostSheet';

function ProspectionDetails() {
  // ReactGA.pageview('/pdm');
  const { id } = useParams();
  const { user } = useCognitoAuth();
  const [optionsComplexities, setOptionsComplexities] = useState([]);
  const [optionsResponsibles, setOptionsResponsibles] = useState([]);
  const [prospection, setProspection] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showModalSupplier, setShowModalSupplier] = useState(false);
  const [showModalResult, setShowModalResult] = useState(false);
  const [showModalRestart, setShowModalRestart] = useState(false);
  const [task, setTask] = useState();
  const [taskIndex, setTaskIndex] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [supplierName, setSupplierName] = useState();
  const validationSchema = Yup.object({
    complexity: Yup.string().required('Campo Obrigatório').nullable(),
    responsible: Yup.string().required('Campo Obrigatório').nullable(),
  });

  const [latestCompetitionDocument, setLatestCompetitionDocument] =  useState(null);

  const [modalType, setModalType] = useState();
  const [supplierId, setSupplierId] = useState();
  const [supplierIndex, setSupplierIndex] = useState();

  // const [competitionFile, setCompetitionFile] = useState();
  const [loadingLatestCompetitionDocument, setLoadingLatestCompetitionDocument] = React.useState(false);

  const [isCheckOrFinance, setIsCheckOrFinance] = useState();
  const [responsibleName, setResponsibleName] = useState();
  const [showModalSimulator, setShowModalSimulator] = useState(false);
  const [showModalStatus, setShowModalStatus] = useState(false);
  const [showModalMassive, setShowModalMassive] = useState(false);

  const [costDocumentReceived, setCostDocumentReceived] = useState(false);
  const [hasMainContact, setHasMainContact] = useState(false);
  const modalCostSheetHelpRef = useRef(null);
  const modalUploadCostSheetRef = useRef(null);
  const modalRemoveSupplierRef = useRef(null);

  const [headers] = useState({
    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
  });

  const [tooltipOpenSimulation, setTooltipOpenSimulation] = useState(false);
  const toggleSimulation = () =>
    setTooltipOpenSimulation(!tooltipOpenSimulation);

  const [tooltipOpenCompetition, setTooltipOpenCompetition] = useState(false);
  const toggleCompetition = () =>
    setTooltipOpenCompetition(!tooltipOpenCompetition);

  const { handleSubmit, values, errors, setFieldValue } = useFormik({
    initialValues: {
      complexity: '',
      responsible: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    onSubmit: values => {
      complexityResponsibleRegistration();
    },
  });

  const openModal = (
    task,
    supplierName,
    isCheckOrFinance,
    responsibleName,
    supplierIndex,
    taskIndex,
    documentsReceived,
    supplierContactId
  ) => {
    setTask(task);
    setSupplierName(supplierName);
    setIsCheckOrFinance(isCheckOrFinance);
    setResponsibleName(responsibleName);
    setSupplierIndex(supplierIndex);
    setTaskIndex(taskIndex);
    setCostDocumentReceived(documentsReceived);
    setHasMainContact(Boolean(supplierContactId));
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModalSupplier = responsibleName => {
    setResponsibleName(responsibleName);
    setShowModalSupplier(true);
  };

  const closeModalSupplier = () => {
    setShowModalSupplier(false);
  };

  const openModalResult = (
    supplierName,
    modalType,
    supplierId,
    supplierIndex
  ) => {
    setSupplierName(supplierName);
    setSupplierId(supplierId);
    setModalType(modalType);
    setSupplierIndex(supplierIndex);
    setShowModalResult(true);
  };

  const openModalRestart = (supplierName, supplierId, supplierIndex) => {
    setSupplierName(supplierName);
    setSupplierId(supplierId);
    setSupplierIndex(supplierIndex);
    setShowModalRestart(true);
  };

  const closeModalResult = () => {
    setShowModalResult(false);
  };

  const closeModalRestart = () => {
    setShowModalRestart(false);
  };

  const openModalSimulator = () => {
    setShowModalSimulator(true);
  };

  const closeModalSimulator = () => {
    setShowModalSimulator(false);
  };

  const openModalStatus = () => {
    setShowModalStatus(true);
  };

  const closeModalStatus = () => {
    setShowModalStatus(false);
  };

  const openModalMassive = () => {
    setShowModalMassive(true);
  };

  const openModalRemove = sup => {
    setSupplierId(sup.supplier_id);
    modalRemoveSupplierRef.current?.toggleOpen();
  };

  const closeModalMassive = () => {
    setShowModalMassive(false);
  };

  const openUploadCostModal = (task, supIndex, supName) => {
    setTask(task);
    setSupplierIndex(supIndex);
    setSupplierName(supName);
    modalUploadCostSheetRef.current?.toggleOpen();
  };
  const getLatestCompetition = (clientId, competitionDocument) => {
    apiConnectSourcing
      .get(`/prospections/${clientId}/get-latest-competition/`)
      .then((response) => {
        setLatestCompetitionDocument(response.data)
      })
      .catch(() => {
        setLatestCompetitionDocument(competitionDocument)
      })
      .finally(() => {
        setLoadingLatestCompetitionDocument(false)
      })
  }

  const getProspection = useCallback(() => {
    setLoading(true);
    setLoadingLatestCompetitionDocument(true)
    apiConnectSourcing
      .get(`/prospections/${id}/`)
      .then(response => {
        if (response.data) {
          setProspection(response.data);
          setFieldValue('complexity', response.data.complexity);
          setFieldValue('responsible', response.data.userInfo?.id);
          getLatestCompetition(response.data.client_id, response.data.competition_document)
        }
        setLoading(false);
      })
      .catch(error => {
        if (error.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
        } else {
          Alert.error(
            'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
            3000
          );
        }

        setLoading(false);
      });
  }, [id, setFieldValue]);
  const includeResponsibles = useCallback(() => {
    apiConnect
      .get('/sourcing-users/', {
        headers,
      })
      .then(response => {
        if (response.data) {
          const responsibles = response.data;
          responsibles.forEach(ob => {
            const result = {
              label: ob.name,
              value: ob.id,
            };
            setOptionsResponsibles(optionsResponsibles => [
              ...optionsResponsibles,
              result,
            ]);
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const includeComplexities = useCallback(() => {
    apiConnectSourcing.get('/complexities/').then(response => {
      if (response.data) {
        if (response.data.results) {
          response.data.results.forEach(ob => {
            const result = {
              label: `Nível ${ob.id}`,
              value: ob.id,
            };
            setOptionsComplexities(optionsComplexities => [
              ...optionsComplexities,
              result,
            ]);
          });
        }
      }
    });
  }, []);

  const complexityResponsibleRegistration = () => {
    setLoadingForm(true);
    apiConnectSourcing
      .put(`/prospections/${id}/`, {
        complexity: parseInt(values.complexity),
        sourcing_manager_user_id: parseInt(values.responsible),
        user: user,
      })
      .then(response => {
        if (response.data) {
          Alert.success('Cadastro efetuado com sucesso!', 3000);
          setProspection(response.data);
        }
        setLoadingForm(false);
      })
      .catch(error => {
        if (error.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
        } else {
          Alert.error(
            'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
            3000
          );
        }
        setLoadingForm(false);
      });
  };
  // const handleUpdateCompetitionDocument = responseCompetitionDocument => {
  //   setProspection({
  //     ...prospection,
  //     competition_document: responseCompetitionDocument,
  //   });
  // };

  // const onHandleChangeCompetitionFile = event => {
  //   setLoadingFile(true);

  //   const fileUploaded = event.target.files[0];
  //   setCompetitionFile(fileUploaded);

  //   let formData = new FormData();
  //   formData.append('file', fileUploaded);
  //   if (event.target.files[0] === null) {
  //     return;
  //   }
  //   apiConnectSourcing
  //     .post(`/prospections/${id}/upload-competition/`, formData)
  //     .then(response => {
  //       if (response.data) {
  //         handleUpdateCompetitionDocument(response.data);
  //         Alert.success(
  //           'Upload do documento da concorrência feito com sucesso!'
  //         );
  //       }
  //       setLoadingFile(false);
  //     })
  //     .catch(error => {
  //       if (error.response?.status === 403) {
  //         Alert.error(error.response.data.message, 50000);
  //       } else {
  //         Alert.error(
  //           'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
  //           3000
  //         );
  //       }
  //       setLoadingFile(false);
  //     });
  // };

  const taskIsDisabled = (task, supplier) => {
    return (!task?.concluded && supplier?.result === false) || !Boolean(task);
  };

  const taskIsCalendarActive = (task, supplier) => {
    return Boolean(task) && !task?.concluded && supplier?.result !== false;
  };

  const showRestartBtn = (task, supplier) => {
    return Boolean(task) && supplier?.result !== false;
  };

  useEffect(() => {
    includeResponsibles();
    includeComplexities();
    getProspection();
  }, [getProspection, includeComplexities, includeResponsibles]);

  return (
    <>
      <HeaderModule classHeader="pdmHeaderModule">
        Controle de Projetos
      </HeaderModule>
      <Tabs tabActive={1}>
        {prospection?.project_type &&
          prospection.project_type.id !== PROJECT_TYPE.NEW_PRODUCT && (
            <div className="derived-badge">
              <span>Projeto derivado: </span>
              <strong>
                {prospection?.derived_project_data.derived_name
                  ? prospection?.derived_project_data.derived_name
                  : '--'}
              </strong>
            </div>
          )}
      </Tabs>
      <div className={styles.mainDiv}>
        <div className={styles.tableBriefing}>
          <Row>
            <Col md={2} lg={3} mdPush={22} lgPush={21}>
              <Link
                className={classNames({
                  [styles.closeButton]: true,
                  [styles.removeDecoration]: true,
                })}
                to="/pdm/prospection">
                Fechar
                <Icon
                  className={classNames({
                    [styles.icon]: true,
                    [styles.removeDecoration]: true,
                  })}
                  icon="sign-in"
                  size="lg"
                />
              </Link>
            </Col>
          </Row>

          <Row>
            <Col md={12} lg={14}>
              <Row>
                <Col md={4}>
                  <label className={styles.bold}> Cliente</label>
                  <div className={styles.fakeButton}>
                    {loading ? (
                      <Loader size="xs" />
                    ) : (
                      prospection?.clientInfo?.name
                    )}
                  </div>
                </Col>
                <Col md={4}>
                  <label className={styles.bold}> Projeto</label>
                  <div className={styles.fakeButton}>
                    {loading ? (
                      <Loader size="xs" />
                    ) : (
                      prospection?.briefing_name
                    )}
                  </div>
                </Col>
                <Col md={4}>
                  <label className={styles.bold}> Status</label>
                  <Button
                    block
                    onClick={openModalStatus}
                    className={classNames({
                      [styles.fakeStatus]: true,
                      [styles.fakeStatusCancel]: prospection?.status.id === 2,
                      [styles.fakeStatusStandby]: prospection?.status.id === 3,
                      [styles.fakeStatusUnfeasible]:
                        prospection?.status.id === 4,
                      [styles.fakeStatusFinish]: prospection?.status.id === 5,
                    })}
                    disabled={prospection?.status.id === 2 || prospection?.status.id === 4}
                    >
                    {loading ? <Loader size="xs" /> : prospection?.status.name}
                  </Button>
                </Col>
                <Col md={4}>
                  <label className={styles.bold}> Complexidades</label>
                  <SelectPicker
                    disabled={loading || Boolean(prospection?.complexity)}
                    data={optionsComplexities}
                    onChange={value => {
                      setFieldValue('complexity', value);
                    }}
                    value={values.complexity}
                    appearance="default"
                    placeholder="Complexidade"
                    renderMenuItem={(label, item) => {
                      return <div>{label}</div>;
                    }}
                  />
                  <span className={styles.error}>
                    {errors.complexity ? errors.complexity : null}
                  </span>
                </Col>
                <Col md={5}>
                  <label className={styles.bold}> Responsável</label>
                  <SelectPicker
                    disabled={loading}
                    data={optionsResponsibles}
                    onChange={value => {
                      setFieldValue('responsible', value);
                    }}
                    value={values.responsible}
                    appearance="default"
                    placeholder="Responsável"
                    renderMenuItem={(label, item) => {
                      return <div>{label}</div>;
                    }}
                  />
                  <span className={styles.error}>
                    {errors.responsible ? errors.responsible : null}
                  </span>
                </Col>
                <Col md={2}>
                  <Button
                    appearance="primary"
                    className={styles.saveButton}
                    onClick={handleSubmit}
                    loading={loadingForm}>
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={12} lg={9}>
              <Row>
                <Col md={7}>
                  {/* <input
                    hidden
                    onChange={event => onHandleChangeCompetitionFile(event)}
                    type="file"
                    multiple
                    ref={filesElement}
                  />

                  <Button
                    id="TooltipCompetitionDocument"
                    block
                    value={competitionFile}
                    loading={loadingFile}
                    className={classNames({
                      [styles.fakeButton3]: !prospection?.competition_document,
                      [styles.fakeButton3Success]:
                        prospection?.competition_document,
                    })}
                    onClick={() => filesElement.current.click()}>
                    {prospection?.competition_document ? (
                      <>
                        <Icon icon="check" style={{ paddingRight: '5px' }} />
                        <strong>Concorrência</strong>
                      </>
                    ) : (
                      'Concorrência'
                    )}
                  </Button>
                  {prospection?.competition_document && (
                  /> */}
                  <a target="_blank" rel="noopener noreferrer" href={latestCompetitionDocument ? latestCompetitionDocument.file : "#"} >
                    <Button
                      id="TooltipCompetitionDocument"
                      block
                      loading={loadingLatestCompetitionDocument}
                      className={clsx({
                        [styles.fakeButton3]: !latestCompetitionDocument,
                        [styles.fakeButton3Success]:
                        latestCompetitionDocument,
                      })}
                      >
                      {latestCompetitionDocument ? (
                        <>
                          <Icon icon="check" style={{ paddingRight: '5px' }} />
                          <strong>Concorrência</strong>
                        </>
                      ) : (
                        'Concorrência'
                      )}
                    </Button>
                  </a>
                  {latestCompetitionDocument && (
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpenCompetition}
                      target="TooltipCompetitionDocument"
                      toggle={toggleCompetition}>
                      {latestCompetitionDocument.hasOwnProperty('file_name') ? latestCompetitionDocument.file_name : latestCompetitionDocument.name}
                    </Tooltip>
                  )}
                </Col>
                <Col md={7}>
                  <Button
                    block
                    onClick={openModalMassive}
                    className={styles.fakeButton3}
                    disabled={!prospection?.has_supplier_documents_received}>
                    Documentos
                  </Button>
                </Col>
                <Col md={10}>
                  <Button
                    id="TooltipSimulation"
                    // disabled={
                    //   !prospection?.has_supplier_documents_received ||
                    //   !prospection?.has_supplier_positive_result
                    // }
                    block
                    onClick={openModalSimulator}
                    className={classNames({
                      [styles.fakeButton3]: !prospection?.costSimulatorFile,
                      [styles.fakeButton3Success]:
                        prospection?.costSimulatorFile,
                    })}>
                    {prospection?.costSimulatorFile ? (
                      <>
                        <Icon icon="check" style={{ paddingRight: '5px' }} />
                        <strong>Simulador de Custos</strong>
                      </>
                    ) : (
                      'Simulador de Custos'
                    )}
                  </Button>
                  {prospection?.costSimulatorFile && (
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpenSimulation}
                      target="TooltipSimulation"
                      toggle={toggleSimulation}>
                      {prospection?.costSimulatorFile.name}
                    </Tooltip>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row>
            <Col mdOffset={17} md={7}>
              {prospection?.competition_document
                ? prospection?.competition_document.name
                : ""}
            </Col>
          </Row> */}
        </div>
        <div className={styles.tableProspectionDetails}>
          <Row style={{ margin: ' 0 20px' }}>
            <Col style={{ textAlign: 'center' }} md={5}>
              Fornecedor
            </Col>
            <Col md={3}>M.P</Col>
            <Col md={3}>Contato</Col>
            <Col md={3}>Reunião Apresentação</Col>
            <Col md={3}>Documentação Concorrência</Col>
            <Col md={3}>
              Retorno P. Financeiro
              <button
                type="button"
                className={styles.helpBtn}
                onClick={() => modalCostSheetHelpRef.current?.toggleOpen()}>
                <HiQuestionMarkCircle />
              </button>
            </Col>
            {/* <Col md={3} lg={3}>
              Check List
            </Col> */}
            <Col md={4}>Resultado</Col>
          </Row>
        </div>
        <Container style={{ marginTop: 10 }}>
          {loading ? (
            <Loading />
          ) : prospection?.suppliers.length > 0 ? (
            prospection.suppliers.map((ob, i) => {
              const selectedSupplier = prospection?.supplierInfo?.find(supplier => supplier.id === ob.supplier_id);
              return (
                <>
                  {!selectedSupplier?.hasOwnProperty('invalid') && (
                    <>
                      <Row key={ob.id} className={styles.rowRetailer}>
                        <Col
                          className={classNames({
                            [styles.columnBegin]: true,
                            [styles.supplierNegativeBegin]:
                              ob?.result === false || ob?.accepted === false,
                            [styles.supplierPositiveBegin]: ob?.result,
                          })}
                          md={5}
                          id={`toggler${i}`}>
                          {selectedSupplier?.name}
                          {selectedSupplier?.is_seller_linked && (
                            <LinkIcon className={styles.linkIcon} />
                          )}
                        </Col>
                        <Col
                          className={classNames({
                            [styles.columns]: true,
                            [styles.supplierNegative]:
                              ob?.result === false || ob?.accepted === false,
                            [styles.supplierPositive]: ob?.result,
                          })}
                          md={3}>
                          {selectedSupplier?.mp === 1
                            ? 'SIM'
                            : 'NÃO'}
                        </Col>
                        <Col
                          className={classNames({
                            [styles.columns]: true,
                            [styles.supplierNegative]:
                              ob?.result === false || ob?.accepted === false,
                            [styles.supplierPositive]: ob?.result,
                          })}
                          md={3}>
                          <Button
                            disabled={taskIsDisabled(ob?.tasks[0], ob)}
                            className={classNames({
                              [styles.buttonCalendar]: ob?.result === null,
                              [styles.buttonCalendarResult]:
                                ob?.result === false || ob?.result,
                            })}
                            onClick={() =>
                              openModal(
                                ob?.tasks[0],
                                selectedSupplier?.name,
                                false,
                                ob?.tasks[0].userInfo
                                  ? ob?.tasks[0].userInfo.name
                                  : prospection.userInfo.name,
                                i,
                                0,
                                ob.documents_received,
                                ob.supplier_contact_id
                              )
                            }>
                            <Icon
                              className={classNames({
                                [styles.icon]: true,
                                [styles.calendarActive]: taskIsCalendarActive(
                                  ob?.tasks[0],
                                  ob
                                ),

                                [styles.calendarConcluded]:
                                  ob?.tasks[0]?.concluded,
                              })}
                              icon="calendar"
                            />
                          </Button>
                          <strong
                            className={classNames({
                              [styles.calendarActive]: taskIsCalendarActive(
                                ob?.tasks[0],
                                ob
                              ),
                              [styles.calendarConcluded]:
                                ob?.tasks[0]?.concluded,
                            })}>
                            {Boolean(ob?.tasks[0])
                              ? moment(
                                  ob?.tasks[0]?.concluded
                                    ? ob?.tasks[0]?.modified_at
                                    : ob?.tasks[0]?.scheduled_to
                                ).format('DD/MM/YYYY')
                              : ''}
                          </strong>
                        </Col>
                        <Col
                          className={classNames({
                            [styles.columns]: true,
                            [styles.supplierNegative]:
                              ob?.result === false || ob?.accepted === false,
                            [styles.supplierPositive]: ob?.result,
                          })}
                          md={3}>
                          <Button
                            disabled={taskIsDisabled(ob?.tasks[1], ob)}
                            className={classNames({
                              [styles.buttonCalendar]: ob?.result === null,
                              [styles.buttonCalendarResult]:
                                ob?.result === false || ob?.result,
                            })}
                            onClick={() =>
                              openModal(
                                ob?.tasks[1],
                                selectedSupplier?.name,
                                false,
                                ob?.tasks[1].userInfo
                                  ? ob?.tasks[1].userInfo.name
                                  : prospection.userInfo.name,
                                i,
                                1,
                                ob.documents_received,
                                ob.supplier_contact_id
                              )
                            }>
                            <Icon
                              className={classNames({
                                [styles.icon]: true,
                                [styles.calendarActive]: taskIsCalendarActive(
                                  ob?.tasks[1],
                                  ob
                                ),

                                [styles.calendarConcluded]:
                                  ob?.tasks[1]?.concluded,
                              })}
                              icon="calendar"
                            />
                          </Button>
                          <strong
                            className={classNames({
                              [styles.calendarActive]: taskIsCalendarActive(
                                ob?.tasks[1],
                                ob
                              ),

                              [styles.calendarConcluded]:
                                ob?.tasks[1]?.concluded,
                            })}>
                            {Boolean(ob?.tasks[1])
                              ? moment(
                                  ob?.tasks[1]?.concluded
                                    ? ob?.tasks[1]?.modified_at
                                    : ob?.tasks[1]?.scheduled_to
                                ).format('DD/MM/YYYY')
                              : ''}
                          </strong>
                        </Col>
                        <Col
                          className={classNames({
                            [styles.columns]: true,
                            [styles.supplierNegative]:
                              ob?.result === false || ob?.accepted === false,
                            [styles.supplierPositive]: ob?.result,
                          })}
                          md={3}>
                          <Button
                            id="TooltipCompetition"
                            disabled={taskIsDisabled(ob?.tasks[2], ob)}
                            className={classNames({
                              [styles.buttonCalendar]: ob?.result === null,
                              [styles.buttonCalendarResult]:
                                ob?.result === false || ob?.result,
                            })}
                            onClick={() =>
                              openModal(
                                ob?.tasks[2],
                                selectedSupplier?.name,
                                false,
                                ob?.tasks[2].userInfo
                                  ? ob?.tasks[2].userInfo.name
                                  : prospection.userInfo.name,
                                i,
                                2,
                                ob.documents_received,
                                ob.supplier_contact_id
                              )
                            }>
                            <Icon
                              className={classNames({
                                [styles.icon]: true,
                                [styles.calendarActive]: taskIsCalendarActive(
                                  ob?.tasks[2],
                                  ob
                                ),
                                [styles.calendarConcluded]:
                                  ob?.tasks[2]?.concluded,
                              })}
                              icon="calendar"
                            />
                          </Button>
                          <strong
                            className={classNames({
                              [styles.calendarActive]: taskIsCalendarActive(
                                ob?.tasks[2],
                                ob
                              ),
                              [styles.calendarConcluded]:
                                ob?.tasks[2]?.concluded,
                            })}>
                            {Boolean(ob?.tasks[2])
                              ? moment(
                                  ob?.tasks[2]?.concluded
                                    ? ob?.tasks[2]?.modified_at
                                    : ob?.tasks[2]?.scheduled_to
                                ).format('DD/MM/YYYY')
                              : ''}
                          </strong>
                        </Col>
                        <Col
                          className={classNames({
                            [styles.columns]: true,
                            [styles.drow]: true,
                            [styles.supplierNegative]:
                              ob?.result === false || ob?.accepted === false,
                            [styles.supplierPositive]: ob?.result,
                          })}
                          md={3}>
                          <Button
                            disabled={taskIsDisabled(ob?.tasks[3], ob)}
                            className={classNames({
                              [styles.buttonCalendar]: ob?.result === null,
                              [styles.buttonCalendarResult]:
                                ob?.result === false || ob?.result,
                            })}
                            onClick={() =>
                              openModal(
                                ob?.tasks[3],
                                selectedSupplier?.name,
                                true,
                                ob?.tasks[3].sourcing_manager_user
                                  ? ob?.tasks[3].userInfo.name
                                  : prospection.userInfo.name,
                                i,
                                3,
                                ob.documents_received,
                                ob.supplier_contact_id
                              )
                            }>
                            <Icon
                              className={classNames({
                                [styles.icon]: true,
                                [styles.calendarActive]: taskIsCalendarActive(
                                  ob?.tasks[3],
                                  ob
                                ),
                                [styles.calendarConcluded]:
                                  ob?.tasks[3]?.concluded,
                              })}
                              icon="calendar"
                            />
                          </Button>
                          <Button
                            disabled={
                              !prospection.cost_sheets_data.find(
                                p =>
                                  Number(p.provider) === Number(ob?.supplier_id)
                              )
                            }
                            className={classNames({
                              [styles.buttonCalendar]: true,
                            })}
                            onClick={() =>
                              openUploadCostModal(
                                ob?.tasks[3],
                                i,
                                selectedSupplier?.name
                              )
                            }>
                            <RiFolderDownloadLine
                              className={classNames({
                                [styles.icon]: true,
                                [styles.success]: ob?.prospect_document_download,
                              })}
                            />
                            <RiFolderUploadLine
                              className={classNames({
                                [styles.icon]: true,
                                [styles.success]:
                                  prospection.cost_sheets_data.find(
                                    p =>
                                      Number(p.provider) ===
                                      Number(ob?.supplier_id)
                                  ),
                              })}
                            />
                          </Button>
                          <strong
                            className={classNames({
                              [styles.fullCenter]: true,
                              [styles.calendarActive]: taskIsCalendarActive(
                                ob?.tasks[3],
                                ob
                              ),
                              [styles.calendarConcluded]:
                                ob?.tasks[3]?.concluded,
                            })}>
                            {Boolean(ob?.tasks[3])
                              ? moment(
                                  ob?.tasks[3]?.concluded
                                    ? ob?.tasks[3]?.modified_at
                                    : ob?.tasks[3]?.scheduled_to
                                ).format('DD/MM/YYYY')
                              : ''}
                          </strong>
                        </Col>
                        {/* <Col
                      className={classNames({
                        [styles.columns]:true, 
                        [styles.supplierNegative]: ob?.result === false,
                        [styles.supplierPositive]: ob?.result,
                      })}
                      md={3}
                      lg={3}>
                      <Button
                        disabled={taskIsDisabled(ob?.tasks[4], ob)}
                        className={classNames({
                          [styles.buttonCalendar]: ob?.result === null,
                          [styles.buttonCalendarResult]:
                            ob?.result === false || ob?.result,
                        })}
                        onClick={() =>
                          openModal(
                            ob?.tasks[4],
                            selectedSupplier?.name,
                            true,
                            ob?.tasks[4].userInfo
                              ? ob?.tasks[4].userInfo.name
                              : prospection.userInfo.name
                          )
                        }>
                        <Icon
                          className={classNames({
                            [styles.calendarActive]:
                            taskIsCalendarActive(ob?.tasks[4], ob),
                            [styles.calendarConcluded]: ob?.tasks[4]?.concluded,
                          })}
                          icon="calendar"
                          size="2x"
                        />
                      </Button>
                      <strong
                        className={classNames({
                          [styles.calendarActive]:
                          taskIsCalendarActive(ob?.tasks[4], ob),
                          [styles.calendarConcluded]: ob?.tasks[4]?.concluded,
                        })}>
                        {Boolean(ob?.tasks[4])
                          ? moment(
                              ob?.tasks[4]?.concluded
                                ? ob?.tasks[4]?.modified_at
                                : ob?.tasks[4]?.scheduled_to
                            )
                              .utc()
                              .format('DD/MM/YYYY')
                          : ''}
                      </strong>
                    </Col> */}
                        <Col
                          className={classNames({
                            [styles.columns]: true,
                            [styles.supplierNegative]:
                              ob?.result === false || ob?.accepted === false,
                            [styles.supplierPositive]: ob?.result,
                          })}
                          md={4}>
                          <Row className={styles.rowBtn}>
                            {ob?.accepted === false ? (
                              <Col
                                md={24}
                                className={styles.colNegativePositive}>
                                <Button
                                  className={styles.returnButton}
                                  onClick={() =>
                                    openModalResult(
                                      selectedSupplier?.name,
                                      null,
                                      ob.id,
                                      i
                                    )
                                  }>
                                  {ob?.result === false
                                    ? 'Negativado por Sourcing '
                                    : 'Negativa pelo Fornecedor '}
                                  <ReloadCompIcon />
                                </Button>
                              </Col>
                            ) : ob?.result === true ? (
                              <Col
                                md={24}
                                className={styles.colNegativePositive}>
                                <div className="m-auto">
                                  <span className={styles.textPositive}>
                                    Positiva
                                  </span>
                                  <Icon
                                    className={classNames({
                                      [styles.thumbsUp]: ob?.result === true,
                                    })}
                                    icon="thumbs-o-up"
                                    size="2x"
                                  />
                                </div>
                              </Col>
                            ) : (
                              <>
                                {ob?.is_delete_ok && (
                                  <Col>
                                    <Button
                                      id={`TooltipRemove-${i}`}
                                      className={classNames({
                                        [styles.buttonThumbs]:
                                          ob?.result === null,
                                        [styles.buttonRemove]: true,
                                      })}
                                      disabled={ob?.result !== null}
                                      onClick={() => openModalRemove(ob)}>
                                      <Icon icon="trash-o" size="2x" />
                                    </Button>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`TooltipRemove-${i}`}>
                                      Excluir fornecedor
                                    </UncontrolledTooltip>
                                  </Col>
                                )}

                                {showRestartBtn(ob?.tasks[0], ob) && (
                                  <Col>
                                    <Button
                                      id={`TooltipReload-${i}`}
                                      className={classNames({
                                        [styles.buttonThumbs]:
                                          ob?.result === null,
                                        [styles.buttonCalendarResult]:
                                          ob?.result === false || ob?.result,
                                      })}
                                      disabled={ob?.result !== null}
                                      onClick={() =>
                                        openModalRestart(
                                          selectedSupplier?.name,
                                          ob.id,
                                          i
                                        )
                                      }>
                                      <Icon
                                        className={classNames({
                                          [styles.thumbsDown]:
                                            ob?.result === false,
                                        })}
                                        icon="reload"
                                        size="2x"
                                      />
                                    </Button>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`TooltipReload-${i}`}>
                                      Reiniciar demandas
                                    </UncontrolledTooltip>
                                  </Col>
                                )}
                                <Col>
                                  <Button
                                    className={classNames({
                                      [styles.buttonThumbs]:
                                        ob?.result === null,
                                      [styles.buttonCalendarResult]:
                                        ob?.result === false || ob?.result,
                                    })}
                                    disabled={ob?.result !== null}
                                    onClick={() =>
                                      openModalResult(
                                        selectedSupplier?.name,
                                        false,
                                        ob.id,
                                        i
                                      )
                                    }>
                                    <Icon
                                      className={classNames({
                                        [styles.thumbsDown]:
                                          ob?.result === false,
                                      })}
                                      icon="thumbs-o-down"
                                      size="2x"
                                    />
                                  </Button>
                                </Col>
                                <Col>
                                  <Button
                                    className={classNames({
                                      [styles.buttonThumbs]:
                                        ob?.result === null,
                                      [styles.buttonCalendarResult]:
                                        ob?.result === false || ob?.result,
                                    })}
                                    disabled={
                                      !ob?.tasks[3]?.concluded ||
                                      ob?.result !== null
                                    }
                                    onClick={() =>
                                      openModalResult(
                                        selectedSupplier?.name,
                                        true,
                                        ob.id,
                                        i
                                      )
                                    }>
                                    <Icon
                                      className={classNames({
                                        [styles.thumbsUp]: ob?.result === true,
                                      })}
                                      icon="thumbs-o-up"
                                      size="2x"
                                    />
                                  </Button>
                                </Col>
                              </>
                            )}
                          </Row>
                        </Col>
                      </Row>
                      <UncontrolledCollapse
                        toggler={`#toggler${i}`}
                        key={`${i}-collapse`}>
                        <SupplierContactCollapse
                          contactsSupplier={ob?.contacts}
                          supplierContactId={ob?.supplier_contact_id}
                          supplierId={ob?.id}
                          prospectionId={prospection?.id}
                          index={i}
                          setProspection={setProspection}
                          prospection={prospection}
                        />
                      </UncontrolledCollapse>
                    </>
                  )}
                </>
              );
            })
          ) : (
            <Row
              style={{
                margin: '20px',
                background: '#f1f1f1',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '5px',
                height: '60px',
              }}>
              <Col md={24}>Sem Fornecedores</Col>
            </Row>
          )}
        </Container>
        <Row>
          <Col
            xsOffset={20}
            xs={4}
            style={{
              textAlign: 'right',
              marginRight: '20px',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '10px',
            }}>
            <Button
              disabled={!prospection?.complexity && !prospection?.userInfo}
              onClick={() => openModalSupplier(prospection?.userInfo?.name)}
              className={styles.ButtonInsertSupplier}>
              Inserir Fornecedor
            </Button>
          </Col>
        </Row>
      </div>
      <ModalProspectionTask
        closeModal={closeModal}
        show={showModal}
        task={task}
        prospection={prospection}
        supplierName={supplierName}
        setProspection={setProspection}
        isCheckOrFinance={isCheckOrFinance}
        responsibleName={responsibleName}
        supplierIndex={supplierIndex}
        taskIndex={taskIndex}
        costDocumentReceived={costDocumentReceived}
        hasMainContact={hasMainContact}
      />
      <ModalInsertSupplier
        closeModal={closeModalSupplier}
        show={showModalSupplier}
        prospection={prospection}
        setProspection={setProspection}
        responsibleName={responsibleName}
        setFieldValue={setFieldValue}
        getProspection={getProspection}
      />
      <ModalProspectionSupplierRestart
        closeModal={closeModalRestart}
        show={showModalRestart}
        prospection={prospection}
        supplierName={supplierName}
        supplierId={supplierId}
        setProspection={setProspection}
        supplierIndex={supplierIndex}
        responsibleName={responsibleName}
      />
      <ModalProspectionResult
        closeModal={closeModalResult}
        show={showModalResult}
        prospection={prospection}
        supplierName={supplierName}
        supplierId={supplierId}
        modalType={modalType}
        setProspection={setProspection}
        supplierIndex={supplierIndex}
        responsibleName={responsibleName}
      />
      <ModalProspectionSimulator
        closeModal={closeModalSimulator}
        show={showModalSimulator}
        prospection={prospection}
        setProspection={setProspection}
      />
      <ModalChangeStatus
        closeModal={closeModalStatus}
        show={showModalStatus}
        prospection={prospection}
        responsibleName={responsibleName}
        setProspection={setProspection}
        getProspection={getProspection}
      />
      <ModalExportMassive
        closeModal={closeModalMassive}
        show={showModalMassive}
        prospection={prospection}
      />
      <ModalCostSheetHelp ref={modalCostSheetHelpRef} />
      <ModalUploadCostSheet
        task={task}
        prospection={prospection}
        supplierIndex={supplierIndex}
        supplierName={supplierName}
        ref={modalUploadCostSheetRef}
      />
      <ModalRemoveSupplier
        supplierId={supplierId}
        prospection={prospection}
        ref={modalRemoveSupplierRef}
      />
    </>
  );
}

export default ProspectionDetails;
